// show iframed videos if user has accepted correct cookies
function showIframe() {
    // get consent frames
    var frames = document.querySelectorAll('.flos-consent-iframe[data-cookie-consent]');
    for (i = 0; i < frames.length; i++) {
        var consents = frames[i].getAttribute('data-cookie-consent');
        var stat = consents.includes('statistic');
        var mark = consents.includes('marketing');
        var func = consents.includes('functional');
        toggleIframe(frames[i], stat, mark, func);
    }
}

// toggle iframe/message
function toggleIframe(frame, statBool, markBool, funcBool) {
    // if cookie category requered AND consent given OR not required = then true
    var stat = (CookieInformation.getConsentGivenFor('cookie_cat_statistic') && statBool) || !statBool;
    var mark = (CookieInformation.getConsentGivenFor('cookie_cat_marketing') && markBool) || !markBool;
    var func = (CookieInformation.getConsentGivenFor('cookie_cat_functional') && funcBool) || !funcBool;

    // load iframes or not
    if (stat && mark && func) {
        // hide consent msg
        frame.querySelector('.msg').style.display = 'none';

        // load iframe content
        var iframes = frame.querySelectorAll('iframe[data-consent-src]');
        for (j = 0; j < iframes.length; j++) {
            // ensure we only show video once, even if events are updated multiple times
            if (iframes[j].getAttribute('src').length === 0) {
                var content = iframes[j].getAttribute('data-consent-src');
                iframes[j].setAttribute('src', content);
                iframes[j].style.display = 'block';
            }
        }

        // hide message & show iframe
        if (!frame.classList.contains('in')) frame.classList.add('in');
    } else {
        // show message & hide iframe
        frame.querySelector('.msg').style.display = 'block';
        frame.classList.remove('in');
        var iframesOut = frame.querySelectorAll('iframe[data-consent-src]');
        for (k = 0; k < iframesOut.length; k++) {
            iframesOut[k].setAttribute('src', '');
            iframesOut[k].style.display = 'none';
        }
    }
}

window.addEventListener('CookieInformationConsentGiven', function (event) {
    showIframe();
});
